import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useProjects } from '../Hooks/useProjects'
import { motion } from 'framer-motion'
import { Icon24ArrowLeftOutline, Icon24ExternalLinkOutline, Icon24CalendarOutline } from '@vkontakte/icons'
import { isMobile } from 'react-device-detect'
import { ReactComponent as ImagePlaceHolder } from '../accets/SVGs/imagePlaceHolder.svg'

// Создаем простой компонент звезды
const StarIcon = ({ width = 20, height = 20, fill = "#FFD700" }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" 
          fill={fill} />
  </svg>
);

function ProjectPage() {
  const { id } = useParams()
  const { getPrById } = useProjects()
  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const scrollRef = useRef(null)
  
  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true)
      const projectData = getPrById(id)[0]
      
      console.log('Загруженные данные проекта:', projectData)
      
      if (projectData) {
        setProject(projectData)
        setLoading(false)
      } else {
        console.error('Проект не найден')
        setLoading(false)
      }
    }
    
    fetchProject()
  }, [id, getPrById])

  const handleBack = () => {
    navigate(-1)
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  }

  const imageVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { 
      scale: 1, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 100, duration: 0.8 }
    }
  }

  if (loading) {
    return (
      <div className="w-full h-screen bg-bg_primary flex items-center justify-center">
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: 360 }}
          transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
          className="w-16 h-16 border-4 border-t-purple-600 border-r-transparent border-b-purple-600 border-l-transparent rounded-full"
        />
      </div>
    )
  }

  if (!project) {
    return (
      <div className="w-full h-screen bg-bg_primary flex flex-col items-center justify-center text-white">
        <h1 className="text-3xl font-bold mb-4">Проект не найден</h1>
        <button 
          onClick={handleBack}
          className="px-4 py-2 bg-purple-600 rounded-lg hover:bg-purple-700 transition-colors"
        >
          Вернуться назад
        </button>
      </div>
    )
  }

  return (
    <div className="w-full min-h-screen bg-bg_primary text-white overflow-x-hidden">
      {/* Верхний баннер с изображением */}
      <div className="relative w-full h-[50vh] overflow-hidden">
        <motion.div
          initial={{ scale: 1.2, opacity: 0.5 }}
          animate={{ scale: 1, opacity: 0.7 }}
          transition={{ duration: 1.5 }}
          className="absolute inset-0 z-0"
        >
          <div 
            className="w-full h-full bg-cover bg-center"
            style={{ backgroundImage: `url(${project.img})` }}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-bg_primary" />
        </motion.div>
        
        <div className="absolute top-6 left-6 z-10">
          <motion.button
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            onClick={handleBack}
            className="flex items-center gap-2 px-4 py-2 bg-black/50 backdrop-blur-md rounded-full hover:bg-black/70 transition-colors"
          >
            <Icon24ArrowLeftOutline />
            <span>Назад</span>
          </motion.button>
        </div>
      </div>

      {/* Основной контент */}
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="container mx-auto px-4 md:px-8 -mt-20 relative z-10"
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Левая колонка с изображением и основной информацией */}
          <div className="md:col-span-1">
            <motion.div 
              variants={imageVariants}
              className="bg-[#1E1E1E] rounded-2xl p-6 shadow-xl mb-6"
            >
              <div className="w-full aspect-square rounded-xl overflow-hidden flex items-center justify-center bg-[#272727] border-2 border-[#686868] mb-4">
                {project.logoLarge ? (
                  <img src={project.logoLarge} alt={project.title} className="w-full h-full object-cover" />
                ) : (
                  <div className="w-[70%] h-[70%] flex items-center justify-center">
                    <ImagePlaceHolder />
                  </div>
                )}
              </div>
              
              <motion.div variants={itemVariants} className="flex items-center gap-2 mb-3">
                <Icon24CalendarOutline width={20} height={20} />
                <span className="text-gray-300">{project.date}</span>
              </motion.div>
              
              <motion.div variants={itemVariants} className="flex items-center gap-2 mb-3">
                <StarIcon width={20} height={20} />
                <span className="text-gray-300">Рейтинг: {project.rating}/10</span>
              </motion.div>
            </motion.div>
            
            {/* Технологии */}
            <motion.div 
              variants={itemVariants}
              className="bg-[#1E1E1E] rounded-2xl p-6 shadow-xl mb-6"
            >
              <h3 className="text-xl font-bold mb-4">Технологии</h3>
              <div className="flex flex-wrap gap-2">
                {project.technologies?.map((tech, index) => (
                  <motion.span
                    key={index}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.1 * index }}
                    className="px-3 py-1 bg-[#2D2D2D] rounded-full text-sm"
                  >
                    {tech}
                  </motion.span>
                ))}
              </div>
            </motion.div>
            
            {/* Команда */}
            {project.team && (
              <motion.div 
                variants={itemVariants}
                className="bg-[#1E1E1E] rounded-2xl p-6 shadow-xl"
              >
                <h3 className="text-xl font-bold mb-4">Команда</h3>
                <div className="space-y-3">
                  {project.team.map((member, index) => (
                    <motion.div 
                      key={index}
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.1 * index }}
                      className="flex items-center gap-3"
                    >
                      <div className="w-10 h-10 rounded-full bg-purple-600 flex items-center justify-center">
                        {member.avatar ? (
                          <img src={member.avatar} alt={member.name} className="w-full h-full rounded-full object-cover" />
                        ) : (
                          <span className="font-bold">{member.name.charAt(0)}</span>
                        )}
                      </div>
                      <div>
                        <p className="font-medium">{member.name}</p>
                        <p className="text-sm text-gray-400">{member.role}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
          
          {/* Правая колонка с описанием и деталями */}
          <div className="md:col-span-2">
            <motion.div 
              variants={itemVariants}
              className="bg-[#1E1E1E] rounded-2xl p-8 shadow-xl mb-6"
            >
              <motion.h1 
                variants={itemVariants}
                className="text-4xl font-bold mb-2"
              >
                {project.title}
              </motion.h1>
              
              <motion.p 
                variants={itemVariants}
                className="text-xl text-purple-400 mb-6"
              >
                {project.topic}
              </motion.p>
              
              <motion.div 
                variants={itemVariants}
                className="mb-8"
              >
                <h2 className="text-2xl font-bold mb-4">О проекте</h2>
                <p className="text-gray-300 leading-relaxed whitespace-pre-line">
                  {project.description || project.des}
                </p>
              </motion.div>
              
              {project.features && (
                <motion.div variants={itemVariants}>
                  <h2 className="text-2xl font-bold mb-4">Ключевые особенности</h2>
                  <ul className="space-y-3">
                    {project.features.map((feature, index) => (
                      <motion.li 
                        key={index}
                        initial={{ x: 20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.1 * index }}
                        className="flex items-start gap-3"
                      >
                        <div className="min-w-6 h-6 rounded-full bg-purple-600 flex items-center justify-center mt-0.5">
                          <span className="text-sm font-bold">{index + 1}</span>
                        </div>
                        <p className="text-gray-300">{feature}</p>
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </motion.div>
            
            {/* Галерея скриншотов */}
            {/* {project.screenshots && (
              <motion.div 
                variants={itemVariants}
                className="bg-[#1E1E1E] rounded-2xl p-8 shadow-xl mb-6"
              >
                <h2 className="text-2xl font-bold mb-6">Галерея</h2>
                <div className="grid grid-cols-2 gap-4">
                  {project.screenshots.map((screenshot, index) => (
                    <motion.div
                      key={index}
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.1 * index }}
                      className="rounded-xl overflow-hidden aspect-video"
                    >
                      <img 
                        src={screenshot} 
                        alt={`Скриншот ${index + 1}`} 
                        className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                      />
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )} */}
            
            {/* Отзывы */}
            {/* {project.testimonials && (
              <motion.div 
                variants={itemVariants}
                className="bg-[#1E1E1E] rounded-2xl p-8 shadow-xl"
              >
                <h2 className="text-2xl font-bold mb-6">Отзывы</h2>
                <div className="space-y-6">
                  {project.testimonials.map((testimonial, index) => (
                    <motion.div
                      key={index}
                      initial={{ scale: 0.95, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ delay: 0.1 * index }}
                      className="bg-[#2D2D2D] p-6 rounded-xl"
                    >
                      <p className="italic mb-4">"{testimonial.text}"</p>
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 rounded-full bg-purple-600 flex items-center justify-center">
                          {testimonial.avatar ? (
                            <img src={testimonial.avatar} alt={testimonial.name} className="w-full h-full rounded-full object-cover" />
                          ) : (
                            <span className="font-bold">{testimonial.name.charAt(0)}</span>
                          )}
                        </div>
                        <div>
                          <p className="font-medium">{testimonial.name}</p>
                          <p className="text-sm text-gray-400">{testimonial.position}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )} */}

            {project.technicalDetails && (
              <motion.div 
                variants={itemVariants}
                className="bg-[#1E1E1E] rounded-2xl p-8 shadow-xl mb-6"
              >
                <h2 className="text-2xl font-bold mb-4">Техническая реализация</h2>
                
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-semibold text-purple-400 mb-2">Архитектура</h3>
                    <p className="text-gray-300">{project.technicalDetails.architecture}</p>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-semibold text-purple-400 mb-2">Frontend</h3>
                    <ul className="space-y-2 text-gray-300">
                      <li><span className="font-medium">Framework:</span> {project.technicalDetails.frontend.framework}</li>
                      <li><span className="font-medium">State Management:</span> {project.technicalDetails.frontend.stateManagement}</li>
                      <li><span className="font-medium">UI Library:</span> {project.technicalDetails.frontend.uiLibrary}</li>
                      <li><span className="font-medium">Styling:</span> {project.technicalDetails.frontend.styling}</li>
                      <li><span className="font-medium">Bundler:</span> {project.technicalDetails.frontend.bundler}</li>
                      <li><span className="font-medium">Performance:</span> {project.technicalDetails.frontend.performance}</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-semibold text-purple-400 mb-2">Backend</h3>
                    <ul className="space-y-2 text-gray-300">
                      <li><span className="font-medium">Runtime:</span> {project.technicalDetails.backend.runtime}</li>
                      <li><span className="font-medium">Database:</span> {project.technicalDetails.backend.database}</li>
                      <li><span className="font-medium">Caching:</span> {project.technicalDetails.backend.caching}</li>
                      <li><span className="font-medium">API:</span> {project.technicalDetails.backend.api}</li>
                      <li><span className="font-medium">Integration:</span> {project.technicalDetails.backend.integration}</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-semibold text-purple-400 mb-2">Deployment</h3>
                    <ul className="space-y-2 text-gray-300">
                      <li><span className="font-medium">Hosting:</span> {project.technicalDetails.deployment.hosting}</li>
                      <li><span className="font-medium">CI/CD:</span> {project.technicalDetails.deployment.ci_cd}</li>
                      <li><span className="font-medium">Monitoring:</span> {project.technicalDetails.deployment.monitoring}</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-semibold text-purple-400 mb-2">Безопасность</h3>
                    <ul className="space-y-2 text-gray-300">
                      <li><span className="font-medium">Аутентификация:</span> {project.technicalDetails.security.authentication}</li>
                      <li><span className="font-medium">Защита данных:</span> {project.technicalDetails.security.dataProtection}</li>
                      <li><span className="font-medium">Валидация:</span> {project.technicalDetails.security.validation}</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-semibold text-purple-400 mb-2">Технические вызовы</h3>
                    <ul className="list-disc pl-5 space-y-1 text-gray-300">
                      {project.technicalDetails.challenges.map((challenge, index) => (
                        <li key={index}>{challenge}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-semibold text-purple-400 mb-2">Метрики производительности</h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="bg-[#2D2D2D] p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Время загрузки</p>
                        <p className="text-xl font-bold">{project.technicalDetails.metrics.loadTime}</p>
                      </div>
                      <div className="bg-[#2D2D2D] p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Время ответа API</p>
                        <p className="text-xl font-bold">{project.technicalDetails.metrics.apiResponseTime}</p>
                      </div>
                      <div className="bg-[#2D2D2D] p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Удержание пользователей</p>
                        <p className="text-xl font-bold">{project.technicalDetails.metrics.userRetention}</p>
                      </div>
                      <div className="bg-[#2D2D2D] p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Частота сбоев</p>
                        <p className="text-xl font-bold">{project.technicalDetails.metrics.crashRate}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}

            {project.projectGoals && (
              <motion.div 
                variants={itemVariants}
                className="bg-[#1E1E1E] rounded-2xl p-8 shadow-xl mb-6"
              >
                <h2 className="text-2xl font-bold mb-4">Цели проекта</h2>
                <ul className="space-y-3">
                  {project.projectGoals.map((goal, index) => (
                    <motion.li 
                      key={index}
                      initial={{ x: 20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.1 * index }}
                      className="flex items-start gap-3"
                    >
                      <div className="min-w-6 h-6 rounded-full bg-green-600 flex items-center justify-center mt-0.5">
                        <span className="text-sm font-bold">✓</span>
                      </div>
                      <p className="text-gray-300">{goal}</p>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            )}

            {project.futureFeatures && (
              <motion.div 
                variants={itemVariants}
                className="bg-[#1E1E1E] rounded-2xl p-8 shadow-xl mb-6"
              >
                <h2 className="text-2xl font-bold mb-4">Планы на будущее</h2>
                <ul className="space-y-3">
                  {project.futureFeatures.map((feature, index) => (
                    <motion.li 
                      key={index}
                      initial={{ x: 20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.1 * index }}
                      className="flex items-start gap-3"
                    >
                      <div className="min-w-6 h-6 rounded-full bg-blue-600 flex items-center justify-center mt-0.5">
                        <span className="text-sm font-bold">+</span>
                      </div>
                      <p className="text-gray-300">{feature}</p>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default ProjectPage