import React from 'react'
import './resumePage.css'
import avatarImage from '../../accets/Avatar.jpg'
import { useNavigate } from 'react-router'
import { isMobile } from 'react-device-detect'


function ResumePage() {


  const navigate = useNavigate()

  const expertise = [
    'React.js',
    'JavaScript',
    'TypeScript',
    'React Query',
    'Redux',
    'Next.js',
    'UI/UX',
    'HTML5/CSS3',
    'Tailwind',
    'REST API',
    'GraphQL',
    'Mapbox GL',
    'Git/GitHub',
    'CI/CD',
    'Docker',
    'AWS',
  ]

  const workExp = [
    {
      title: 'Frontend-разработчик',
      сompanyName: 'N&D team',
      project: 'EvenTracker · Alumni · Eventracker (VK mini app)',
      role: 'Frontend-разработчик, UI/UX дизайнер, DevOps',
      date: '2022-2024',
      aboutWork: 'Разработал архитектуру фронтенд-части проекта. Создал адаптивный интерфейс с React.js и Tailwind CSS. Интегрировал MapBox GL JS с оптимизацией для геоданных. Настроил CI/CD пайплайны. Провел более 200 код-ревью. Оптимизировал производительность, сократив время загрузки на 35%.',
    },
    {
      title: 'Фриланс-разработчик',
      сompanyName: 'Upwork, FL.ru',
      project: 'Корпоративные сайты, SPA, интернет-магазины',
      role: 'Full-stack разработчик',
      date: '2022-2024',
      aboutWork: 'Разработал более 15 проектов для клиентов из разных отраслей. Создавал интерактивные веб-приложения на React.js и Next.js. Интегрировал платежные системы и CRM. Оптимизировал SEO и производительность. Работал с международными заказчиками, соблюдая сроки и высокое качество.',
    },
    {
      title: 'Технический консультант',
      сompanyName: 'Бизнес-инкубатор IT-Park',
      project: 'Менторство стартапов',
      role: 'Технический эксперт, Консультант',
      date: '2023-2024',
      aboutWork: 'Консультировал 5+ стартапов по техническим вопросам. Помогал с выбором технологического стека и архитектуры. Проводил код-ревью и аудит существующих решений. Участвовал в оценке технической реализуемости идей. Помогал с подготовкой технической документации для инвесторов.',
    },
    {
      title: 'Стажер-разработчик',
      сompanyName: 'Сургутский государственный университет',
      project: 'SurGUEvents',
      role: 'Frontend-разработчик, UI/UX дизайнер',
      date: '2021-2022',
      aboutWork: 'Разработал прототип мобильного приложения в Figma. Реализовал фронтенд с React Native. Интегрировал с бэкендом через REST API. Внедрил систему аутентификации. Оптимизировал производительность для устройств с низкими характеристиками.',
    },
  ]

  const courses = [
    {
      text: 'AWS Certified Cloud Practitioner',
      date: '2024',
      provider: 'AWS'
    },
    {
      text: 'VK FRESH CODE',
      date: '2024',
      provider: 'VK'
    },
    {
      text: 'Frontend-pro',
      date: '2024',
      provider: 'SkillBox'
    },
    {
      text: 'Meta Frontend Developer Certificate',
      date: '2023',
      provider: 'Coursera'
    },
    {
      text: 'JavaScript: The Hard Parts',
      date: '2022',
      provider: 'Frontend Masters'
    }
  ]

  const skills = [
    {
      title: 'Разработка пользовательских интерфейсов',
      points: ['Создание интерактивных интерфейсов', 'Разработка SPA с React.js и Next.js', 'Реализация сложных пользовательских сценариев', 'Оптимизация UI для разных устройств'],
    },
    {
      title: 'Работа с геопространственными данными',
      points: ['Интеграция с Mapbox GL JS', 'Оптимизация отображения геоданных', 'Визуализация GeoJSON данных', 'Реализация карт с кластеризацией'],
    },
    {
      title: 'DevOps практики',
      points: ['Настройка CI/CD пайплайнов', 'Работа с Docker', 'Деплой на AWS', 'Мониторинг производительности'],
    },
    {
      title: 'Разработка и тестирование',
      points: ['Unit и интеграционные тесты', 'Внедрение TDD', 'Автоматизация тестирования', 'Оптимизация производительности'],
    },
  ]

  const achievements = [
    {
      title: 'Vk mini apps',
      date: '2024',
      description: 'Победа в конкурсе по разработке мини-приложений ВКонтакте с призовым фондом 1 млн руб.'
    },
    {
      title: 'Хантатон',
      date: '2024',
      description: 'Топ-3 в Югорском Хантатоне с проектом визуализации городских данных'
    },
    {
      title: 'Студенческий стартап',
      date: '2023-2024',
      description: 'Грант от Фонда содействия инновациям на развитие проекта Eventracker (2 млн руб.)'
    },
    {
      title: 'Digital Challenge',
      date: '2023',
      description: 'Победа в хакатоне по разработке веб-приложений с проектом Alumni'
    },
    {
      title: 'Акселератор ИТМО',
      date: '2023',
      description: 'Участие в акселераторе ИТМО с проектом Eventracker, привлечение pre-seed инвестиций'
    },
  ]

  const startupProjects = [
    {
      title: 'Eventracker',
      role: 'Сооснователь, CTO',
      date: '2022-2024',
      description: 'Платформа для организации и поиска мероприятий с геолокацией. Привлекли инвестиции, получили грант ФСИ. Разработал архитектуру, руководил командой разработчиков из 4 человек.'
    },
    {
      title: 'Alumni',
      role: 'Технический директор',
      date: '2023-2024',
      description: 'Социальная сеть для выпускников вузов. Разработал MVP, внедрил систему рекомендаций на основе интересов пользователей. Проект вошел в акселератор ИТМО.'
    },
  ]

  return (
    <div className='resume-wrapper min-h-screen'>
      <div className='resume min-h-[1200px]'>
        <div className='left-side-menu'>
          <div className='avatar w-full h-[200px] flex items-center justify-center pt-[30px]'>
            <div className='shape w-[150px] h-[150px] rounded-full bg-[#fff] overflow-hidden'>
              <img src={avatarImage} alt='' className='w-[100%] h-[100%] object-cover scale-125'/>
            </div>
          </div>
          <div className='contacts pl-[40px]'>
            <div className='mt-[30px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Контакты</h1>
            </div>
            <div className='w-full h-[2px] bg-[#dfdfdf]'/>
            <div className='phone my-[10px]'>
              <h1 className='text-[16px] font-bold'>Телефон</h1>
              <h2 className='text-[14px] font-light text-[#d1d1d1]'>89519720411</h2>
            </div>
            <div className='mail my-[10px]'>
              <h1 className='text-[16px] font-bold'>Почта</h1>
              <h2 className='text-[14px] font-light text-[#d1d1d1]'>one@ognev.xyz</h2>
            </div>
            <div className='address my-[10px]'>
              <h1 className='text-[16px] font-bold'>Адрес</h1>
              <h2 className='text-[14px] font-light text-[#d1d1d1]'>г. Казань, Советский район</h2>
            </div>
          </div>
          <div className='education pl-[40px]'>
            <div className='mt-[25px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Образование</h1>
            </div>
            <div className='w-full h-[2px] bg-[#dfdfdf]'/>
            <div className='first my-[10px]'>
              <h1 className='text-[16px] font-bold'>Университет</h1>
              <h2 className='text-[14px] font-light text-[#d1d1d1]'>
                КФУ, Институт вычислительной математики и ИТ
              </h2>

            </div>
          </div>
          <div className='specs pl-[40px]'>
            <div className='mt-[25px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Технический стек</h1>
            </div>
            <div className='w-full h-[2px] bg-[#dfdfdf] mb-[10px]'/>
            <div className='grid grid-cols-2 gap-x-[5px]'>
              {expertise.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='spec flex flex-row gap-x-[8px] items-center mb-[6px]'>
                    <div className='s-circle w-[4px] h-[4px] bg-[#dfdfdf] rounded-full'/>
                    <div className='s-text'>
                      <h1 className='text-[13px] font-thin'>{item}</h1>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='languages pl-[40px]'>
            <div className='mt-[15px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Языки</h1>
            </div>
            <div className='w-full h-[2px] bg-[#dfdfdf] mb-[10px]'/>
            <div>
              <h1 className='text-[13px] font-extralight'>Английский (B2)</h1>
            </div>
            <div>
              <h1 className='text-[13px] font-extralight'>Русский (родной)</h1>
            </div>
          </div>
        </div>
        <div className='content-about text-[#383c4c] p-[40px]'>
          <div className='h-auto mt-[20px]'>
            <div className='me-data flex flex-row gap-x-[10px]'>
              <h1 className='f-name text-[30px] font-extrabold'>Огнев</h1>
              <h1 className='nd-name text-[30px] font-light'>Никита Сергеевич</h1>
            </div>
            <div className='work-title'>
              <h1 className='text-[18px] font-light'>Frontend-разработчик | UI/UX дизайнер | DevOps</h1>
            </div>
            <div className='about-me'>
              <p className='text-[14px] mt-[8px]'>
                Frontend-разработчик с 3-летним опытом создания современных веб-приложений. 
                Специализируюсь на разработке интерактивных интерфейсов с React.js, TypeScript и современных технологий. 
                Имею опыт работы с геоданными и картографическими API. Обладаю навыками UI/UX дизайна и DevOps.
                Ориентирован на создание высококачественных и производительных решений.
              </p>
            </div>
          </div>
          
          <div className='achievements'>
            <div className='mt-[30px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Достижения</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div className='grid grid-cols-2 gap-x-[15px] gap-y-[10px] mt-[15px]'>
              {achievements.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='achievement-box p-[15px] border border-[#dfdfdf] rounded-md'>
                    <div className='flex justify-between items-center'>
                      <h1 className='text-[16px] font-bold'>{item.title}</h1>
                      <span className='text-[12px] text-[#6c7185]'>{item.date}</span>
                    </div>
                    <p className='text-[13px] mt-[3px]'>{item.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
          
          <div className='startup-projects'>
            <div className='mt-[30px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Стартап-проекты</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div className='grid grid-cols-2 gap-x-[15px] gap-y-[10px] mt-[15px]'>
              {startupProjects.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='startup-box p-[15px] border border-[#dfdfdf] rounded-md'>
                    <div className='flex justify-between items-center'>
                      <h1 className='text-[16px] font-bold'>{item.title}</h1>
                      <span className='text-[12px] text-[#6c7185]'>{item.date}</span>
                    </div>
                    <p className='text-[13px] font-medium text-[#6c7185] mt-[3px]'>{item.role}</p>
                    <p className='text-[12px] mt-[5px]'>{item.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
          
          <div className='work-exp'>
            <div className='mt-[30px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Опыт работы</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div className='work-box mt-[15px]'>
              {workExp.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='item-box relative w-full h-auto flex flex-col gap-y-[8px] px-[20px] border-l-2 mb-[25px]'>
                    <div className='date-title flex justify-between items-center'>
                      <div className='dot absolute left-0 top-0 translate-x-[-50%] translate-y-[25%] w-[12px] h-[12px] bg-[#dfdfdf] rounded-full border-[2px]'/>
                      <h1 className='text-[16px] font-bold'>{item.title}</h1>
                      <span className='text-[12px] font-semibold'>{item.date}</span>
                    </div>
                    <div className='company-name text-[13px] font-medium text-[#6c7185]'>
                      <h1>{item.сompanyName}</h1>
                    </div>
                    <div className='project text-[13px] font-medium'>
                      <span className='font-bold'>Проект: </span>{item.project}
                    </div>
                    <div className='role text-[13px] font-medium'>
                      <span className='font-bold'>Роль: </span>{item.role}
                    </div>
                    <div className='about-work text-[12px] font-light mt-[3px]'>
                      <p>{item.aboutWork}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          
          <div className='courses min-h-[auto] w-full'>
            <div className='mt-[30px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Дополнительное образование</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div className='grid grid-cols-3 gap-x-[10px] gap-y-[10px] mt-[15px]'>
              {courses.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='course-box p-[15px] border border-[#dfdfdf] rounded-md'>
                    <div className='flex justify-between items-center'>
                      <h1 className='text-[14px] font-bold'>{item.text}</h1>
                      <span className='text-[12px] text-[#6c7185]'>{item.date}</span>
                    </div>
                    <p className='text-[12px] mt-[3px] text-[#6c7185]'>{item.provider}</p>
                  </div>
                )
              })}
            </div>
          </div>
          
          <div className='work-skills mt-[30px] w-full'>
            <div className='mt-[30px] mb-[5px]'>
              <h1 className='text-[22px] font-bold'>Профессиональные навыки</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div className='grid grid-cols-2 gap-x-[15px] gap-y-[10px] mt-[15px]'>
              {skills.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='skill-box p-[15px] border border-[#dfdfdf] rounded-md'>
                    <h1 className='text-[16px] font-bold mb-[5px]'>{item.title}</h1>
                    <ul className='list-disc pl-[15px]'>
                      {item.points.map((point) => {
                        return (
                          <li key={crypto.randomUUID()} className='text-[13px] mb-[3px]'>{point}</li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {isMobile?
        <div className='pc-btns-resume w-[150px] h-auto flex flex-col fixed right-[25px] top-[25px] gap-y-5 cursor-pointer select-none'>
          <div onClick={() => navigate('/')} className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Посетить сайт</h1>
          </div>
          <a href='http://public-file-storage.ognev.xyz/Resume.pdf'  className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Скачать резюме</h1>
          </a>
        </div>
      :
        <div className='pc-btns-resume w-[150px] h-auto flex flex-col fixed right-[50px] top-[50px] gap-y-5 cursor-pointer select-none'>
          <div onClick={() => navigate('/')} className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Посетить сайт</h1>
          </div>
          <a href='http://public-file-storage.ognev.xyz/Resume.pdf' className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Скачать резюме</h1>
          </a>
        </div>
      }
    </div>
  )
}

export default ResumePage