import { useState } from "react"
import eventBg4 from '../accets/IMGs/bgForEventCard/bg_event_4.jpg'
import eventBg5 from '../accets/IMGs/bgForEventCard/bg_event_5.jpg'
import eventBg6 from '../accets/IMGs/bgForEventCard/bg_event_6.jpg'
import eventBg7 from '../accets/IMGs/bgForEventCard/bg_event_7.jpg'
import eventBg8 from '../accets/IMGs/bgForEventCard/bg_event_8.jpg'
import eventBg9 from '../accets/IMGs/bgForEventCard/bg_event_9.jpg'
import eventBg10 from '../accets/IMGs/bgForEventCard/bg_event_10.jpg'
import eventBg11 from '../accets/IMGs/bgForEventCard/bg_event_11.jpg'
import eventBg12 from '../accets/IMGs/bgForEventCard/bg_event_12.jpg'

import imgLogo from '../accets/IMGs/icon_r50_512 1.png'

export const useProjects = () => {
    const link = 'https://vk.com/services?ref=catalog_recent&w=app51780803_467966674'
    const projects = [
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker VK mini app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: eventBg10,
            des: 'Vk миниапп, который делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Он также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            description: `EvenTracker - это инновационное VK мини-приложение, разработанное для того, чтобы сделать поиск и участие в мероприятиях максимально удобным и интересным.

Наше приложение решает проблему поиска интересных событий и единомышленников для совместного времяпрепровождения. Мы создали интуитивно понятный интерфейс с продвинутыми фильтрами, которые помогают пользователям быстро находить мероприятия, соответствующие их интересам.

Одна из ключевых особенностей EvenTracker - это умная система рекомендаций, которая анализирует предпочтения пользователя и предлагает ему наиболее релевантные события. Кроме того, мы интегрировали социальные функции, позволяющие пользователям создавать группы по интересам и находить новых друзей.

Проект был реализован с использованием современных технологий и фреймворков, что обеспечивает высокую производительность и отзывчивость интерфейса даже при большой нагрузке.`,
            id: 1,
            logo: imgLogo,
            logoLarge: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8.5',
            technologies: ['React', 'JavaScript', 'VKUI', 'Redux', 'Node.js', 'MongoDB'],
            features: [
                'Умная система рекомендаций мероприятий на основе интересов пользователя',
                'Продвинутые фильтры для поиска событий по категориям, дате, месту проведения',
                'Интерактивная карта мероприятий с геолокацией',
                'Возможность создания и управления собственными событиями',
                'Социальные функции для общения с единомышленниками',
                'Интеграция с календарем и напоминаниями'
            ],
            team: [
                { name: 'Огнев Никита', role: 'Frontend-разработчик, UI/UX дизайнер' },
                { name: 'Вовчинский Дмитрий', role: 'Backend-разработчик, DevOps' }
            ],
            screenshots: [
                eventBg10,
                eventBg11,
                eventBg12,
                eventBg4
            ],
            testimonials: [
                { 
                    name: 'Алексей Петров', 
                    position: 'Организатор мероприятий',
                    text: 'EvenTracker значительно упростил процесс продвижения наших мероприятий. Теперь мы можем легко достучаться до целевой аудитории и получать больше участников.' 
                },
                { 
                    name: 'Мария Иванова', 
                    position: 'Активный пользователь',
                    text: 'Благодаря этому приложению я нашла много интересных мероприятий и познакомилась с новыми людьми. Интерфейс очень удобный и интуитивно понятный.' 
                }
            ],
            technicalDetails: {
                architecture: 'Клиент-серверная архитектура с микросервисами',
                frontend: {
                    framework: 'React.js с хуками и функциональными компонентами',
                    stateManagement: 'Redux с Redux Toolkit для управления состоянием',
                    uiLibrary: 'VKUI для соответствия дизайну экосистемы ВКонтакте',
                    styling: 'CSS-in-JS с использованием Styled Components',
                    bundler: 'Webpack с оптимизацией для быстрой загрузки',
                    performance: 'Ленивая загрузка компонентов и оптимизация рендеринга'
                },
                backend: {
                    runtime: 'Node.js с Express.js',
                    database: 'MongoDB для хранения данных о мероприятиях и пользователях',
                    caching: 'Redis для кэширования часто запрашиваемых данных',
                    api: 'RESTful API с JWT-аутентификацией',
                    integration: 'Интеграция с API ВКонтакте для авторизации и социальных функций'
                },
                deployment: {
                    hosting: 'Облачный хостинг на AWS',
                    ci_cd: 'Автоматизированный CI/CD пайплайн с GitHub Actions',
                    monitoring: 'Sentry для отслеживания ошибок и производительности'
                },
                security: {
                    authentication: 'OAuth 2.0 через VK Connect',
                    dataProtection: 'Шифрование чувствительных данных и HTTPS',
                    validation: 'Строгая валидация входных данных на клиенте и сервере'
                },
                challenges: [
                    'Оптимизация производительности при большом количестве одновременных пользователей',
                    'Разработка алгоритма рекомендаций, учитывающего множество факторов',
                    'Обеспечение бесперебойной работы в условиях ограничений платформы VK Mini Apps',
                    'Создание интуитивно понятного UX для разных категорий пользователей'
                ],
                metrics: {
                    loadTime: '< 2 секунд на среднем устройстве',
                    apiResponseTime: '< 200 мс для 95% запросов',
                    userRetention: '68% пользователей возвращаются в течение недели',
                    crashRate: '< 0.5% сессий'
                }
            },
            projectGoals: [
                'Создать удобную платформу для поиска и организации мероприятий',
                'Объединить людей со схожими интересами',
                'Упростить процесс планирования досуга',
                'Помочь организаторам в продвижении их мероприятий'
            ],
            futureFeatures: [
                'Интеграция с платежными системами для покупки билетов',
                'Расширенная аналитика для организаторов мероприятий',
                'Система лояльности и вознаграждений для активных пользователей',
                'Персонализированные уведомления на основе машинного обучения'
            ]
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker native mobile app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: eventBg12,
            des: 'Нативное приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            description: `EvenTracker для мобильных устройств - это полноценное нативное приложение, разработанное для платформ iOS и Android, которое предоставляет пользователям максимально удобный доступ к поиску и организации мероприятий.

Мы создали это приложение, чтобы предложить пользователям более глубокий и персонализированный опыт взаимодействия с платформой. Нативная разработка позволила нам использовать все возможности мобильных устройств, включая push-уведомления, геолокацию с высокой точностью и интеграцию с другими приложениями.

Особое внимание мы уделили оптимизации производительности и энергопотребления, чтобы приложение работало быстро и не разряжало батарею устройства. Интерфейс разработан с учетом современных тенденций мобильного дизайна и принципов Material Design и Human Interface Guidelines.

Одной из ключевых особенностей нативного приложения является возможность работы в офлайн-режиме, что позволяет пользователям иметь доступ к сохраненным мероприятиям даже при отсутствии интернет-соединения.`,
            id: 2,
            logo: imgLogo,
            logoLarge: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '9.2',
            technologies: ['React Native', 'TypeScript', 'Redux', 'Firebase', 'GraphQL', 'Expo'],
            features: [
                'Нативный пользовательский интерфейс для iOS и Android',
                'Офлайн-режим работы с синхронизацией данных',
                'Push-уведомления о новых мероприятиях и обновлениях',
                'Интеграция с нативными картами и геолокацией',
                'Расширенные возможности персонализации профиля',
                'Дополненная реальность для навигации на крупных мероприятиях'
            ],
            team: [
                { name: 'Огнев Никита', role: 'Mobile-разработчик, UI/UX дизайнер' },
                { name: 'Вовчинский Дмитрий', role: 'Backend-разработчик, DevOps' }
            ],
            screenshots: [
                eventBg12,
                eventBg5,
                eventBg6,
                eventBg7
            ],
            testimonials: [
                { 
                    name: 'Дмитрий Соколов', 
                    position: 'iOS разработчик',
                    text: 'Как профессионал в области мобильной разработки, я впечатлен качеством кода и архитектурой приложения. Видно, что команда разработчиков действительно знает свое дело.' 
                },
                { 
                    name: 'Анна Кузнецова', 
                    position: 'Студентка',
                    text: 'Приложение стало для меня незаменимым помощником в поиске интересных мероприятий. Особенно нравится функция рекомендаций - она действительно предлагает то, что мне интересно!' 
                }
            ],
            technicalDetails: {
                architecture: 'Многослойная архитектура с нативными компонентами',
                frontend: {
                    framework: 'React Native с использованием хуков и функциональных компонентов',
                    stateManagement: 'Redux с Redux Saga для асинхронных операций',
                    uiLibrary: 'Собственные UI компоненты с поддержкой нативных элементов',
                    styling: 'Styled Components и нативные стили',
                    bundler: 'Metro с оптимизацией для быстрого запуска',
                    performance: 'Мемоизация компонентов и оптимизация рендеринга'
                },
                backend: {
                    runtime: 'Node.js с Express.js и Firebase Functions',
                    database: 'Firebase Firestore для хранения данных в реальном времени',
                    caching: 'Локальное кэширование и Firebase Cache',
                    api: 'GraphQL API с Apollo Client',
                    integration: 'Интеграция с нативными API устройств (камера, геолокация, уведомления)'
                },
                deployment: {
                    hosting: 'App Store и Google Play',
                    ci_cd: 'Fastlane и GitHub Actions для автоматизации релизов',
                    monitoring: 'Firebase Crashlytics и Performance Monitoring'
                },
                security: {
                    authentication: 'Firebase Authentication с поддержкой биометрии',
                    dataProtection: 'Шифрование данных на устройстве и в сети',
                    validation: 'Строгая типизация с TypeScript и валидация на всех уровнях'
                },
                challenges: [
                    'Обеспечение одинакового пользовательского опыта на iOS и Android',
                    'Оптимизация потребления батареи при использовании геолокации',
                    'Реализация офлайн-режима с синхронизацией данных',
                    'Интеграция с нативными API различных устройств'
                ],
                metrics: {
                    loadTime: '< 1.5 секунд на современных устройствах',
                    apiResponseTime: '< 150 мс для 95% запросов',
                    userRetention: '72% пользователей возвращаются в течение недели',
                    crashRate: '< 0.3% сессий'
                }
            },
            projectGoals: [
                'Создать полноценное нативное приложение с высокой производительностью',
                'Обеспечить доступ к функциям платформы даже в офлайн-режиме',
                'Использовать нативные возможности устройств для улучшения UX',
                'Предоставить более глубокую персонализацию, чем в веб-версии'
            ],
            futureFeatures: [
                'Интеграция с Apple Watch и Android Wear',
                'Поддержка дополненной реальности для навигации на мероприятиях',
                'Голосовой помощник для поиска и планирования мероприятий',
                'Социальные функции с элементами геймификации'
            ]
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker web app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: eventBg11,
            des: 'Web-приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 3,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            technicalDetails: {
                architecture: 'SPA с микрофронтендами и микросервисной бэкенд-архитектурой',
                frontend: {
                    framework: 'React.js с использованием Next.js для SSR',
                    stateManagement: 'Context API и React Query для управления состоянием',
                    uiLibrary: 'Material-UI с кастомной темой',
                    styling: 'Emotion и CSS Modules',
                    bundler: 'Webpack с разделением кода и оптимизацией',
                    performance: 'Прогрессивная загрузка и оптимизация Core Web Vitals'
                },
                backend: {
                    runtime: 'Node.js с NestJS',
                    database: 'PostgreSQL с TypeORM',
                    caching: 'Redis и CDN для статических ресурсов',
                    api: 'RESTful API с OpenAPI спецификацией',
                    integration: 'Интеграция с различными API для расширения функциональности'
                },
                deployment: {
                    hosting: 'Vercel для фронтенда и AWS для бэкенда',
                    ci_cd: 'GitHub Actions с автоматическим тестированием и деплоем',
                    monitoring: 'Datadog и New Relic для мониторинга производительности'
                },
                security: {
                    authentication: 'JWT с поддержкой OAuth 2.0',
                    dataProtection: 'HTTPS и шифрование чувствительных данных',
                    validation: 'Zod для валидации данных на всех уровнях'
                },
                challenges: [
                    'Оптимизация производительности для пользователей с медленным интернетом',
                    'Создание адаптивного дизайна для всех типов устройств',
                    'Обеспечение доступности (a11y) для всех пользователей',
                    'Масштабирование архитектуры для поддержки растущего числа пользователей'
                ],
                metrics: {
                    loadTime: '< 2.5 секунд (FCP)',
                    apiResponseTime: '< 300 мс для 95% запросов',
                    userRetention: '65% пользователей возвращаются в течение месяца',
                    crashRate: '< 0.1% сессий'
                }
            },
            projectGoals: [
                'Создать полнофункциональную веб-платформу для поиска и организации мероприятий',
                'Обеспечить высокую доступность и кроссбраузерность',
                'Предоставить расширенные аналитические возможности для организаторов',
                'Интегрировать социальные функции для создания сообществ'
            ],
            futureFeatures: [
                'Интеграция с календарями Google и Outlook',
                'Расширенные возможности для организаторов мероприятий',
                'Система рекомендаций на основе машинного обучения',
                'Маркетплейс для продажи билетов и услуг'
            ]
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Alumni telegram mini app',
            topic: 'Alumni поможет тебе поддержвать отношения с твоими одногрупниками после обучения, и узнать кто чего добился, и чем может помочь',
            img: eventBg4,
            des: 'Telegram-miniapp который помогает решать какие то жизненные проблемы с помощью старых знакомств, информируя пользователя о том чем могут помочь его бывшые однокурсники',
            id: 4,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            technicalDetails: {
                architecture: 'Telegram Mini App с интеграцией в экосистему Telegram',
                frontend: {
                    framework: 'React с использованием Telegram Web App API',
                    stateManagement: 'MobX для реактивного управления состоянием',
                    uiLibrary: 'Telegram UI компоненты и кастомные элементы',
                    styling: 'CSS Modules с адаптацией под темы Telegram',
                    bundler: 'Vite с оптимизацией для быстрой загрузки',
                    performance: 'Минимизация размера бандла и оптимизация для мобильных устройств'
                },
                backend: {
                    runtime: 'Node.js с Express и Telegram Bot API',
                    database: 'MongoDB для хранения профилей и связей',
                    caching: 'In-memory кэширование и Redis',
                    api: 'RESTful API с интеграцией Telegram Bot API',
                    integration: 'Интеграция с образовательными платформами и LinkedIn'
                },
                deployment: {
                    hosting: 'Vercel для фронтенда и Heroku для бэкенда',
                    ci_cd: 'GitHub Actions для непрерывной интеграции',
                    monitoring: 'Sentry для отслеживания ошибок'
                },
                security: {
                    authentication: 'Telegram Login Widget и JWT',
                    dataProtection: 'Шифрование данных и соответствие GDPR',
                    validation: 'Строгая валидация входных данных'
                },
                challenges: [
                    'Интеграция с ограниченным API Telegram Mini Apps',
                    'Оптимизация UX в рамках Telegram клиента',
                    'Создание системы верификации образовательных данных',
                    'Обеспечение приватности при сохранении социальных функций'
                ],
                metrics: {
                    loadTime: '< 1 секунда в Telegram клиенте',
                    apiResponseTime: '< 200 мс для 95% запросов',
                    userRetention: '70% пользователей возвращаются в течение месяца',
                    crashRate: '< 0.2% сессий'
                }
            },
            projectGoals: [
                'Создать платформу для поддержания связей между выпускниками',
                'Помочь в профессиональном нетворкинге и карьерном росте',
                'Упростить поиск специалистов среди бывших однокурсников',
                'Создать сообщество взаимопомощи выпускников'
            ],
            futureFeatures: [
                'Интеграция с профессиональными социальными сетями',
                'Система менторства и карьерных консультаций',
                'Организация встреч выпускников и профессиональных мероприятий',
                'Расширенные возможности для образовательных учреждений'
            ]
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Surgu Events web app',
            topic: 'Навигатор мероприятий в университете',
            img: eventBg5,
            des: 'Web-приложение которое помогает организовывать и проводить университетские мероприятия',
            id: 5,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Surgu Events native mobile app',
            topic: 'Навигатор мероприятий в университете',
            img: eventBg6,
            des: 'Нативное приложение которое помогает организовывать и проводить университетские мероприятия',
            id: 6,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Friendify native mobile app',
            topic: 'Friendify поможет тебе спланировать время которое ты хочешь провести с друзьями',
            img: eventBg7,
            des: 'Нативное-приложение которое помогает выбрать как провести время компанией и подробно распланировать встречи',
            id: 7,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Friendify web app',
            topic: 'Friendify поможет тебе спланировать время которое ты хочешь провести с друзьями',
            img: eventBg8,
            des: 'Web-приложение которое помогает выбрать как провести время компанией и подробно распланировать встречи',
            id: 8,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Ognev site',
            topic: 'Мой сайт)',
            img: eventBg9,
            des: 'Тут можно узнать обо мне',
            id: 9,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
    ]

    /**
     * @id
     */
    const getPrById = (id) => {
        let res = projects.filter((item) => item.id == id)
        return res
    }


    return {
        projects,
        getPrById,
    }
}