export const useAchivements = () => {

    const education = [
        {
            title: 'University education',
            date: 'Now',
            des: 'Казанский (Приволжский) Федеральный Университет по направлению Информационные Системы и Технологии '
        },
        {
            title: 'Frontend-pro',
            date: '2024',
            des: 'SkillBox, frontend-разработчик'
        },
        {
            title: 'VK FRESH CODE',
            date: '2024',
            des: 'VK, frontend & devOps'
        },
        {
            title: 'AWS Certified Cloud Practitioner',
            date: '2024',
            des: 'Amazon Web Services, cloud solutions, DevOps'
        },
        {
            title: 'Meta Frontend Developer Professional Certificate',
            date: '2023',
            des: 'Coursera, React, frontend'
        },
        {
            title: 'JavaScript: The Hard Parts',
            date: '2022',
            des: 'Frontend Masters, High level JS, async'
        },
    ]

    const workExperience = [
        {
          title: 'Стажировка в Университете СурГУ',
          сompanyName: 'Сургутский государственный университет',
          project: 'SurGUEvents',
          role: 'Frontend & UI|UX designer',
          date: '2021-2022',
          des: 'Создал прототип приложения в фигме, сверстал фронт нативного приложения для мобильного устройства используя reactNative, интегрировал фронт с беком ',
        },
        {
          title: 'Работа в стартап команде',
          сompanyName: 'N&D team',
          project: 'EvenTracker(standalone web/native) · Alumni · Eventracker(vk mini app)',
          role: 'Frontend & UI|UX designer & devOps',
          date: '2022-2024',
          des: 'Работал над прототипом, проводил кодревью (front), поднял дев и прод сервера, настроил CI|CD, осуществил интеграцию с MapBox GL JS и последуещей оптимизацией, сверстал адаптивный дизайн, отвечал за frontend в целом в приложении'
        },
      ]

    const achivements = [
        {
            title: 'Future now',
            date: '2022-2024',
            des: 'Победа в конкурсе проектов по развитию города среди молодежи'
        },
        {
            title: 'Digital Chalenge',
            date: '2023-2024',
            des: 'Победа в Хакатоне по разработке веб приложений (в комманде)'
        },
        {
            title: 'Студентческий стартап',
            date: '2023-2024',
            des: 'Победа в Студ стартапе от фонда содействия инновациям (в комманде)'
        },
        {
            title: 'Хантатон',
            date: '2024',
            des: 'Топ 3 в Югорском Хантатоне'
        },
        {
            title: 'Vk mini apps',
            date: '2024',
            des: 'Победа в Конкурсе по разработке мини-приложений в рамках ВКОНТАКТЕ (в комманде)'
        },
    ]

    return {
        workExperience,
        achivements,
        education
    }


}